import React from "react";

const RouterDashboard = () => {
  return (
    <div className="p-8 pt-32 bg-black text-white">
      coming soon
    </div>
  );
};

export default RouterDashboard;
